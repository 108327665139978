import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import Gallery from "../components/gallery"
import SEO from "../components/seo"

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulMain(sort: { fields: createdAt, order: DESC }, limit: 6) {
        nodes {
          photos {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          sub {
            photos {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
      contentfulWebsite {
        description
      }
    }
  `)

  const { nodes } = data.allContentfulMain
  const { description } = data.contentfulWebsite

  const images = []

  nodes.forEach(ele => {
    if (ele.photos) {
      ele.photos.forEach(({ fluid }) => {
        images.push({ fluid })
      })
    }
    if (ele.sub) {
      ele.sub.forEach(({ photos }) => {
        photos.forEach(({ fluid }) => {
          images.push({ fluid })
        })
      })
    }
  })

  return (
    <Fragment>
      <SEO title={description} />
      <Gallery
        images={images.slice(0, 10)}
        pathname={location.pathname}
        title="Current Photos"
      />
    </Fragment>
  )
}

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default IndexPage
